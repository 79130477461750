import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Axios from "axios";
import VueAxios from "vue-axios";
// set auth header
Axios.defaults.headers.common["Authorization"] = store.state.token;

import "./assets/custom.scss";
import "./assets/css/app.css";

import { BootstrapIconsPlugin } from "bootstrap-icons-vue";
import "bootstrap";

import VueSweetalert2 from 'vue-sweetalert2';
// import 'sweetalert2/dist/sweetalert2.min.css';

//Components
import HeaderTop from "@/components/main/Header.vue";
import lineChat from "@/components/chart/lineChart.vue";
import TableLite from "@/components/GlobalComponents/GlobalTable.vue";
import GlobalCard from "@/components/GlobalComponents/GlobalCard.vue";
import ImageUpload from "@/components/main/imageUpload.vue"
import MultipleImageUpload from "@/components/main/multipleImageUpload.vue"
import FileUpload from "@/components/main/FileUpload";
import CarImageUpload from "@/components/main/CarImageUpload.vue"
import CarMultipleImageUpload from "@/components/main/CarMultipleImageUpload.vue"
import ImageDragList from "@/components/main/ImageDragList.vue"
import CarImageDragList from "@/components/main/CarImageDragList.vue"
import PropertyImageDragList from "@/components/main/PropertyImageDragList.vue"
import InputBanner from "@/components/main/InputBanner.vue"

import { VueEditor } from "vue3-editor";
import Pagination from 'v-pagination-3';
import { ModelListSelect, MultiListSelect } from "vue-search-select";
import "vue-search-select/dist/VueSearchSelect.css";

import { Tabs, Tab } from 'vue3-tabs-component';
import VueformMultiselect from '@vueform/multiselect';

import VueGoogleMaps from '@fawmi/vue-google-maps';

import Vue3TagsInput from 'vue3-tags-input';

import Datepicker from 'vue3-datepicker';

import { LoadingPlugin } from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import directives from "./directives/";

const app = createApp(App);
directives(app);
app.use(store);
app.use(router);
app.use(BootstrapIconsPlugin);
app.use(LoadingPlugin);
app.use(VueAxios, Axios);
app.use(VueSweetalert2);
app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDbX0dLs5QU4_7dWbzX_Qe6Kk5BE65VXVE',
    libraries: "places"
    // language: 'de',
  },
})
//Components App
app.component("HeaderTop", HeaderTop);
app.component("lineChat", lineChat);
app.component("table-lite", TableLite);
app.component("vue-editor", VueEditor);
app.component('pagination', Pagination);
app.component('global-card', GlobalCard);
app.component('tabs', Tabs);
app.component('tab', Tab);
app.component('image-upload', ImageUpload);
app.component('multiple-image-upload', MultipleImageUpload);
app.component('car-image-upload', CarImageUpload);
app.component('car-multiple-image-upload', CarMultipleImageUpload);
app.component('file-upload', FileUpload);
app.component('model-list-select', ModelListSelect);
// app.component('multi-select', Multiselect);
app.component('Multiselect', VueformMultiselect);
app.component('vue3-tags-input', Vue3TagsInput);
app.component('datepicker', Datepicker);
app.component('image-drag-list', ImageDragList);
app.component('car-image-drag-list', CarImageDragList);
app.component('property-image-drag-list', PropertyImageDragList);
app.component('input-banner', InputBanner);

app.mount("#app");

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (store.getters.isLoggedIn) {
      next({
        path: "/",
      });
    } else {
      next();
    }
  }
});
