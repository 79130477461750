<script setup>
import { onMounted, ref } from "@vue/runtime-core";
const props = defineProps(['data']);
</script>
<template>
    <div class="flex justify-center w-full">
        <div class="block max-w-sm rounded-lg bg-white shadow-lg dark:bg-neutral-700 w-full">
            <router-link to="/" data-te-ripple-init data-te-ripple-color="light">
                <img
                    v-if="props.data.logo"
                    class="rounded-t-lg"
                    :src="props.data.logo"
                    :alt="props.data.directory_title" />
                <img
                    v-else
                    class="rounded-t-lg"
                    src="@/assets/images/preview-13.jpg"
                    alt="" />
            </router-link>
            <div class="p-5">
                <h5
                    class="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
                    {{ props.data.directory_title }}
                </h5>
                <div class="text-xs text-slate-600 tracking-wider mb-2" v-for="cat in props.data.category">
                    <span class="uppercase font-bold">{{ cat.directory_category_title }}</span>
                    <br>{{cat.directory_subcategory_title }}
                </div>
            </div>
        </div>
    </div>
</template>