import BusinessService from "@/services/BusinessService"
import store from "@/store";
import cookie from 'cookiejs';
const business = {
    state: () => ({
        BCL: null,
        BCLDROP: null,
        BSCL: null,
        BSCLDROP: null,
        ADDBUSINESS: null,
        BUSINESSLIST: null,
        BUSINESSOBJECT: null,
        BUSINESSDELETE: null,
        BUSINESSBANNER: null,
        BANNERPOST: null,
        BUSINESSACTIVE: null
    }),
    getters: {
        BCL: (state) => {
            return state.BCL !== null ? state.BCL : [];
        },
        BCLDROP: (state) => {
            return state.BCLDROP !== null ? state.BCLDROP : [];
        },
        BSCL: (state) => {
            return state.BSCL !== null ? state.BSCL : [];
        },
        BSCLDROP: (state) => {
            return state.BSCLDROP !== null ? state.BSCLDROP : [];
        },
        ADDBUSINESS: (state) => {
            return state.ADDBUSINESS;
        },
        BUSINESSLIST: (state) => {
            return state.BUSINESSLIST !== null ? state.BUSINESSLIST : [];
        },
        BUSINESSOBJECT: (state) => {
            return state.BUSINESSOBJECT !== null ? state.BUSINESSOBJECT : [];
        },
        BUSINESSDELETE: (state) => {
            return state.BUSINESSDELETE !== null ? state.BUSINESSDELETE : false;
        },
        BUSINESSBANNER: (state) => {
            return state.BUSINESSBANNER !== null ? state.BUSINESSBANNER : {};
        },
        BANNERPOST: (state) => {
            return state.BANNERPOST !== null ? state.BANNERPOST : {};
        },
        BUSINESSACTIVE: (state) => {
            return state.BUSINESSACTIVE !== null ? state.BUSINESSACTIVE : {};
        }
    },
    mutations: {
        BCL_MUTATIONS: (state, data) => {
            state.BCL = data
        },
        BCL_DROP_MUTATIONS: (state, data) => {
            state.BCLDROP = data
        },
        BSCL_MUTATIONS: (state, data) => {
            state.BSCL = data
        },
        BSCL_DROP_MUTATIONS: (state, data) => {
            state.BSCLDROP = data
        },
        ADD_BUSINESS: (state, data) => {
            state.ADDBUSINESS = data
        },
        BUSINESS_LIST: (state, data) => {
            state.BUSINESSLIST = data
        },
        BUSINESS_OBJECT: (state, data) => {
            state.BUSINESSOBJECT = data
        },
        BUSINESS_DELETE: (state, data) => {
            state.BUSINESSDELETE = data
        },
        BUSINESS_BANNER: (state, data) => {
            state.BUSINESSBANNER = data;
        },
        BANNER_POST: (state, data) => {
            state.BANNERPOST = data;
        },
        BUSINESS_ACTIVE: (state, data) => {
            state.BUSINESSACTIVE = data;
        }
    },
    actions: {
        AddCategoryBusiness: async ({commit}, json) => {
            const response = await BusinessService.AddCategoryBusinessService(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                response.status == true ? store.dispatch("getSuccess", response.message) : store.dispatch("getError", response.message);
            }
        },
        BusinessCategoryList: async ({commit}, json) => {
            const response = await BusinessService.businessCategoryList(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("BCL_MUTATIONS", response);
            }
        },
        BusinessCategoryDropList: async ({commit}) => {
            const response = await BusinessService.businessCategoryDropList();
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("BCL_DROP_MUTATIONS", response);
            }
        },
        AddSubCategoryBusiness: async ({commit}, json) => {
            const response = await BusinessService.addSubCategoryBusinessService(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                response.status == true ? store.dispatch("getSuccess", response.message) : store.dispatch("getError", response.message);
            }
        },
        BusinessSubCategoryList: async ({commit}, json) => {
            const response = await BusinessService.businessSubCategoryList(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("BSCL_MUTATIONS", response);
            }
        },
        BusinessSubCategoryDropList: async ({commit}, json) => {
            const response = await BusinessService.businessSubCategoryDroplist(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("BSCL_DROP_MUTATIONS", response);
            }
        },
        AddBusiness: async ({commit}, json) => {
            const response = await BusinessService.addBusiness(json);
            // response.status == true ? store.dispatch("getSuccess", response.message) : store.dispatch("getError", response.message);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("ADD_BUSINESS", response);
            }
        },
        BusinessList: async({commit}, json) => {
            const response = await BusinessService.businessList(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("BUSINESS_LIST", response);
            }
        },
        BusinessObject: async({commit}, json) => {
            const response = await BusinessService.businessObjectApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("BUSINESS_OBJECT", response);
            }
        },
        BusinessDeleteImage: async({commit}, json) => {
            const response = await BusinessService.businessDeleteImageApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("BUSINESS_DELETE", response);
            }
        },
        BusinessUpdate: async ({commit}, json) => {
            const response = await BusinessService.addBusiness(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("ADD_BUSINESS", response);
            }
        },
        BusinessBanner: async ({commit}, json) => {
            const response = await BusinessService.businessBannerApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("BUSINESS_BANNER", response);
            }
        },
        bannerPost: async ({commit}, json) => {
            const response = await BusinessService.bannerPostApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("BANNER_POST", response);
            }
        },
        BusinessActive: async ({commit}, json) => {
            const response = await BusinessService.businessActiveApi(json);
            if(response.status === false) {
                cookie.remove('logKey');
                location.reload();
            } else {
                commit("BUSINESS_ACTIVE", response);
            }
        }
    }
}

export default business;